import { useState, useEffect } from 'react';
import { useLocation } from "react-router-dom";

const Search = () => {

   const [ query, setQuery ] = useState(null);
   const search = useLocation().search;
   const name = new URLSearchParams(search).get('s');

   useEffect(() => {
      setQuery(name);
   },[]);

   if(query == null) {
      return (
         <section className="content">
         <h1>Search</h1>
         </section>
      )
   }

   return (
      <section className="content">
         <h1>Search results for '{query}'</h1>
      </section>
   )
}
export default Search;
