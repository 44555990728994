import { useInView } from 'react-intersection-observer';
import React, { useState, CSSProperties } from 'react';

const LazyImage = ({ width, height, src, webp, alt, delay, ...rest }) => {
  const { ref, inView } = useInView({
    triggerOnce: true,
    rootMargin: '-0px 0px'
  });

  const [loaded, setLoaded] = useState(false);

  var delay = { "--primary-delay": delay+"ms", "--secondary-delay": ((delay*1)+300)+"ms" };

  const handleLoad = () => {
     setLoaded(true);
 }

  const Img = () => {
      if (webp) {
        return <picture>
        <source srcSet={webp} alt={alt} type="image/webp" />
        <source srcSet={src} alt={alt} type="image/jpeg" />
        <img srcSet={src} alt={alt} {...rest} onLoad={() => handleLoad()} />
        </picture>;
      } else {
        return <img
          {...rest}
          srcSet={src}
          width={width}
          height={height}
          alt={alt}
          onLoad={() => handleLoad()}
        />;
      }
    }

  /*if(webpurl) {
     Img = <picture>
      <source srcset={webpurl} type="image/webp" />
      <source srcset={src} type="image/jpeg" />
      <img src={src} alt={alt} {...rest} />
      </picture>
 } else {
    Img = <img
      {...rest}
      src={src}
      width={width}
      height={height}
      alt={alt}
    />
}*/

  return (
    <div className={"lazyload " + ( loaded ? "is-visible" : "") }
      ref={ref}
    >
      <div className="lazyload__overlay" style={ delay }></div>
      {inView ? (
         <Img />
      ) : null}
    </div>
   /*<picture>
      <source srcset="img/awesomeWebPImage.webp" type="image/webp">
      <source srcset="img/creakyOldJPEG.jpg" type="image/jpeg">
      <img src="img/creakyOldJPEG.jpg" alt="Alt Text!">
   </picture>*/
  );
};

export default LazyImage;
