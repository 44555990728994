import {ReactComponent as PadlockIcon } from '../../svgs/padlock.svg';
import { useSelector } from 'react-redux';
import { useMutation, gql } from '@apollo/client';
import React, { useEffect, createRef } from 'react';

const selectItems = state => state.items;

const CheckOut = () => {
   const items = useSelector(selectItems);
   const itemObj = [];
   const lineItems = [];
   items.map(item => { itemObj.push({'variantId':item.variant,'quantity':item.quantity}) });
   const checkoutRef = React.createRef();

   lineItems.push({'lineItems':itemObj});

   const GET_CHECKOUT = gql`
   mutation checkoutCreate($lineItems: [CheckoutLineItemInput!]) {
  checkoutCreate(input: {
    lineItems: $lineItems
  }) {
    checkout {
       id
       webUrl
       lineItems(first: 5) {
         edges {
           node {
             title
             quantity
           }
         }
       }
    }
  }
}
`;

   const [getCheckout, { data, loading, error }] = useMutation(GET_CHECKOUT, { variables: { lineItems: itemObj } });

   React.useEffect(() => {
      getCheckout();
   }, []);

   if (loading) return <p>Loading...</p>;
   if (error) return <p>Error :(</p>;

   if(!data) {
      return <p>d</p>
   }


   if(!data.checkoutCreate.checkout) {
      return <></>
   }

   const url = data.checkoutCreate.checkout.webUrl;

   const handleOutbound = () => {
      checkoutRef.current.classList.add('is-redirecting');
   }

   return (
      <a href={url} className="btn__checkout" ref={checkoutRef} onClick={() => handleOutbound()}>
         <span className="btn__txt"><span className="btn__checkout__icon"><PadlockIcon /></span>Continue to Checkout</span>
         <span className="btn__redirecting"></span>
         <span className="btn__redirecting-text">Redirecting you to the checkout...</span>
      </a>
   )
}

export default CheckOut;
