import { loadState } from '../localStorage.js';

const persistedState = loadState();

const initState = {
  items: persistedState
}

function nextTodoId(todos) {
  const maxId = todos.reduce((maxId, todo) => Math.max(todo.id, maxId), -1)
  return maxId + 1
}

const cartReducer = (state = initState,action) => {
    switch (action.type) {
      case 'cart/cartAdded': {
      return {
        ...state,
        items: [
          ...state.items,
          {
            id: nextTodoId(state.items),
            variant: action.payload.variant,
            title: action.payload.title,
            desc: action.payload.desc,
            url: action.payload.url,
            img: action.payload.img,
            price: action.payload.price,
            quantity: 1
          }
        ]
      }
    }
    case 'cart/cartIncreaseQuantity': {
    return {
      ...state,
      items: state.items.map(item => {
          if (item.id !== action.payload.id) {
            return item
          }
          return {
            ...item,
            quantity: action.payload.quantity
          }
        })
    }
  }
    case 'cart/cartDeleted': {
      return {
      ...state,
      items: state.items.filter(item => item.id !== action.payload.id)
      }
    }
    case 'menu/filterBackground': {
      return {
      ...state,
      bool: !action.payload.bool
      }
    }
      default:
      return state
   }


}

export default cartReducer;
