import {Helmet} from "react-helmet";
import {ReactComponent as AdviceIcon } from '../../svgs/advice.svg';
import { useLocation, Link } from "react-router-dom";
import { useQuery, gql } from '@apollo/client';
import React, { useState, useEffect, createRef } from 'react';
import { useSelector,useDispatch } from 'react-redux';
import LazyImage from '../lazyload.js';
import { formatNumber } from '../../utils.js';

const selectItems = state => state.items;

const SingleProduct = ({openbasket}) => {

   const dispatch = useDispatch();

   const location = useLocation();
   const [handle, setHandle] = useState(null);
   const [variant, setVariant] = React.useState(null);
   const items = useSelector(selectItems);
   const addtoCartRef = React.createRef();

   useEffect(() => {
      let url = location.pathname.split("/");
      url = url.filter(item => item);
      setHandle(url.at(-1));
   },[]);

   const GET_PRODUCTBYHANDLE = gql`
query Product($handle: String!) {
  productByHandle(handle: $handle) {
    id
    title
    publishedAt
    description
    handle
    productType
    vendor

    priceRange {
      minVariantPrice {
        amount
      }
    }
	 variants(first:10) {
      edges {
        node {
          id
          title
          currentlyNotInStock
          priceV2 {
            amount
            currencyCode
          }
          selectedOptions {
            name
            value

          }
        }
      }
    }
    collections(first: 5) {
      edges {
        node {
          handle
          title
        }
      }
    }
    availableForSale
    images(first: 250) {
      edges {
        node {
        	id
        	altText
        	originalSrc
         transformedSrc(scale: 1, preferredContentType: WEBP)
        }
      }
    }
  }
}
`;

      const { loading, error, data } = useQuery(
         GET_PRODUCTBYHANDLE,
         { variables: { handle: handle } }
      );


   if (loading) return (
       <div className="single-product" itemType="http://schema.org/Product" itemScope>
       <div className="single-product__desc"></div>
       <div className="single-product__carousel"></div>
       <div className="single-product__addtocart"></div>
       </div>
   );
   if (error) return <p>Error :(</p>;

   const variantCount = data.productByHandle.variants.edges.length;
	let variantSelector;

   function handleVariantChange(event) {
		setVariant(event.target.value);
	}

   const productCategory = data.productByHandle.collections.edges[0].node.title;
   const productName = data.productByHandle.vendor + " " + data.productByHandle.title;
   const productPrice = data.productByHandle.priceRange.minVariantPrice.amount;
   const productImg = data.productByHandle.images.edges[0].node.originalSrc;
   const productImgAlt = data.productByHandle.images.edges[0].node.altText;
   const productWEBP = data.productByHandle.images.edges[0].node.transformedSrc;
   const categoryURL = "/products/"+data.productByHandle.collections.edges[0].node.handle;

   const handleProductAdd = () => {
      let variantid = variant == null ? data.productByHandle.variants.edges[0].node.id : variant;
      const variants = data.productByHandle.variants.edges;
      const selectedVariant = variants.find(variant => variant.node.id == variantid);
      const inBasket = items.find(item => item.variant == variantid);
      const productDesc = selectedVariant.node.title;
      const productUrl = categoryURL+"/"+handle;

      if(inBasket) {
         dispatch({ type: 'cart/cartIncreaseQuantity', payload: { id:inBasket.id,quantity:inBasket.quantity+1 } });
      } else {
         dispatch({ type: 'cart/cartAdded', payload: {title:productName,desc:productDesc,img:productImg,url:productUrl,price:productPrice,variant:variantid} });
      }
      addtoCartRef.current.innerHTML = 'Added!';
      openbasket();
  }

	if(variantCount > 1) {
		variantSelector = <select onChange={e => handleVariantChange(e)}>
		 { data.productByHandle.variants.edges.map((product, index) => (
			 <option value={product.node.id} key={index}>{product.node.title}</option>
		 ))
		 }
		 </select>
	}

  return (
    <div className="single-product" itemType="http://schema.org/Product" itemScope>
      <div className="single-product__desc">
        <div className="breadcrumb">
           <Link to="/">Home</Link> /
           <Link to="/products">Products</Link> /
           <Link to={ categoryURL }>{ productCategory }</Link>
        </div>
        <h1 itemProp="name">{ productName }</h1>
        <div className="single-product__desc__product-meta" itemProp="offers" itemType="http://schema.org/Offer" itemScope>
        <meta itemProp="priceCurrency" content="GBP" />
        <meta itemProp="price" content={ productPrice } />
        <p className="single-product__price">£{ formatNumber(productPrice) }</p>
        <p className="single-product__stock" itemProp="availability" content="https://schema.org/InStock">In stock. Ships within 48 hours.</p>
        </div>
        <p>{ data.productByHandle.description }</p>
        <p className="display-none"><strong>Key stats</strong><br />Color: Black</p>
        <p className="display-none"><strong>We recommend because...</strong><br />Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmo.</p>
      </div>
      <div className="single-product__carousel">
         <div className="single-product__image">
            <LazyImage width="" height="" src={ productImg } webp={ productWEBP } delay="0" alt={ productImgAlt } />
         </div>
      </div>
      <div className="single-product__addtocart">
        <div className="single-product__options">
        { variantSelector }
        </div>
        <div className="single-product__addtocart__btn">
          <button type="button" onClick={() => handleProductAdd()} ref={addtoCartRef}>Add to cart</button>
          <p><small>Free shipping for orders for over £60.<br />Find out more about <Link to="/delivery-charges/">Delivery, Returns and Exchanges</Link>.</small></p>
        </div>
      </div>
      <Helmet>
          <title>{ productName } Table Tennis { productCategory } - TopspinTT</title>
          <meta name="description" content={ "Top quality Table Tennis "+ productCategory +" for only £"+data.productByHandle.priceRange.minVariantPrice.amount+", as used by the pros. Free shipping on orders over £60." } />
      </Helmet>
      <div className="buying-guide">
         <div className="buying-guide__icon"><AdviceIcon /></div>
        <h5>Not sure if this bat suits your playing style?</h5>
        <p>If you’re looking for product advice on this bat, either <Link to="/contact/">speak to a member of the TopspinTT team</Link> or read our bat purchasing guide.</p>
      </div>
    </div>
  )
}

export default SingleProduct;
