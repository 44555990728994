import React, { useState, useEffect } from 'react';

const Search = ({searchProp}) => {
	const [search, setsearch] = React.useState({...searchProp});

	React.useEffect(() => {
      setsearch(searchProp);
  }, [searchProp])

	return (
		<div className={"search-overlay "  + (search ? 'search-overlay--visible' : '')}>
		</div>
   );
}
export default Search;
