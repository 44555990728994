import LazyImage from '../../lazyload.js';
import ProductRow from '../../products/product-row.js';
import {ReactComponent as Donic} from '../../../svgs/donic.svg';
import {ReactComponent as Sanwei} from '../../../svgs/sanwei.svg';
import {ReactComponent as Newgy} from '../../../svgs/newgy.svg';
import {ReactComponent as Nittaku} from '../../../svgs/nittaku.svg';
import {ReactComponent as TitleShape} from '../../../svgs/title-shape.svg';
import { useQuery, gql } from '@apollo/client';
import { Link } from "react-router-dom";
import {Helmet} from "react-helmet";

const Home = () => {
   return (
      <>
     <section className="hero">
      <div className="hero__img">
         <LazyImage width="" height="" src="/assets/images/hero.jpg" webp="/assets/webp/hero.webp" alt="Image of Sam Walker playing table tennis" delay="0" />
      </div>
      <div className="hero__txt">
         <h1>Table Tennis for Professionals</h1>
      </div>
     </section>
     <hr />
     <section className="our-range--wrapper">
        <header>
           <h2>Our Range</h2>
        </header>
        <div className="our-range">
            <div className="range">
               <div className="range__img">
                  <LazyImage width="" height="" src="/assets/images/blades_980.jpg" webp="/assets/webp/blades_980.webp" delay="0" />
               </div>
               <h3>Rubbers</h3>
               <p>We've partnered with the best brands to bring you the technological cutting edge of table tennis.</p>
               <p><Link to="/products/rubbers">Shop now</Link></p>
            </div>
            <hr />
            <div className="range">
               <div className="range__img">
                  <LazyImage width="" height="" src="/assets/images/rubbers_980.jpg" webp="/assets/webp/rubbers_980.webp" delay="0" />
               </div>
               <h3>Blades</h3>
               <p>Whether you prefer a looser style of play or love a tight, fast rally, we've got the blades to suit your batting technique.</p>
               <p><Link to="/products/blades">Shop now</Link></p>
            </div>
            <hr />
            <div className="range">
               <div className="range__img">
                  <LazyImage width="" height=""  src="/assets/images/tables_980.jpg" webp="/assets/webp/tables_980.webp" delay="0" />
               </div>
               <h3>Tables</h3>
               <p>For practise or for competition, we've got a wide range of tables to suit your needs.</p>
               <p><Link to="/products/tables">Shop now</Link></p>
            </div>
        </div>
     </section>
     <hr />
     <section className="featured-products--wrapper">
         <header>
            <h2>Featured products</h2>
         </header>
         <div className="featured-products">
            <div className="featured-product">
               <div className="featured-product__img">
               <LazyImage width="" height=""  src="/assets/images/bluestorm_980.jpg" webp="/assets/webp/bluestorm_980.webp" delay="0" alt="Donic Bluestorm" />
               </div>
               <h3>Donic Bluestorm Pro</h3>
               <p>This newly developed pimple rubber combination offers direct touch and excellent rebound, assisting topspins even under the most difficult circumstances.</p>
               <p><Link to="/products/rubbers">Shop now</Link> <Link to="/products/rubbers" className="btn--without-border">Shop all rubbers</Link></p>
            </div>
            <hr />
            <div className="featured-product">
               <div className="featured-product__img">
               <LazyImage width="" height=""  src="/assets/images/bluegrip_980.jpg" webp="/assets/webp/bluegrip_980.webp" delay="0" alt="Donic Bluegrip" />
               </div>
               <h3>Donic Bluegrip</h3>
               <p>This newly developed pimple rubber combination offers direct touch and excellent rebound, assisting topspins even under the most difficult circumstances.</p>
               <Link to="/products/rubbers">Shop now</Link> <Link to="/products/rubbers" className="btn--without-border">Shop all rubbers</Link>
            </div>
         </div>
     </section>
     <hr />
     <section className="product-row--wrapper">
        <header>
           <h2>New to the site</h2>
        </header>
       <ProductRow collection="New" numberofproducts="4" />
     </section>
     <hr />
     <section className="brands--wrapper">
        <header>
           <h2 className="has-background">Our brands<span className="has-background__shape"><TitleShape /></span></h2>
           <p>We’ve partnered with the brands that all pros and dedicated enthusiasts use on a daily basis as well as in competition.</p>
        </header>
        <div className="brands">
            <div className="brand"><Donic /></div>
            <div className="brand"><Sanwei /></div>
            <div className="brand"><Newgy /></div>
            <div className="brand"><Nittaku /></div>
        </div>
     </section>
     <hr />
     <section className="product-row--wrapper">
        <header>
           <h2>New to the site</h2>
        </header>
        <ProductRow collection="New" numberofproducts="4" />
     </section>
     <hr />
     <section className="table-hire--wrapper">
         <div className="table-hire__img">
         <LazyImage width="" height="" src="/assets/images/tablehire.jpg" alt="Table hire image" webp="/assets/webp/tablehire.webp" delay="0" />
         </div>
         <div className="table-hire__txt">
           <h3>Table Tennis Hire</h3>
           <p>Premium table tennis table and equipment hire for tournaments, office events, sports days, parties and more.</p>
           <a href="/table-hire/" className="btn">Find out more</a>
        </div>
     </section>
     <Helmet>
         <meta name="description" content="Table Tennis for Professionals. Suppliers of high quality table tennis equipment throughout the UK. Free shipping on orders over £60." />
     </Helmet>
     </>
   )
}
export default Home;
