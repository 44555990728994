import Discount from './discount.js';
import Menu from './menu.js';
import Basket from './basket.js';
import Search from './search.js';
import ShopMenu from './shop-menu.js';
import {ReactComponent as BasketIcon } from '../../svgs/basket.svg';
import {ReactComponent as SearchIcon } from '../../svgs/search.svg';
import {ReactComponent as LogoMark } from '../../svgs/logomark.svg';
import React, { useState, useEffect, useRef } from 'react';
import { useSelector } from 'react-redux';

const selectItems = state => state.items;

const Header = (props) => {

	const onblur = props.onblur;
	const basketVisibility = props.basketprop;
	const [visibility, setVisibility] = React.useState(basketVisibility);
	const [search, setSearch] = React.useState(false);
	const [shopnav, setShopNav] = React.useState(false);
	const [menunav, setMenuNav] = React.useState(false);
	const ref = React.useRef(null);

	const items = useSelector(selectItems);

	let basketItems = 0;
	items.map(item => { basketItems += item.quantity; });

	let openSearch = () => {
		setSearch(!search);
		setVisibility(false);
		setShopNav(false);
		onblur(false);
	}

	let openBasket = () => {
		setVisibility(!visibility);
		setSearch(false);
		setShopNav(false);
		onblur(!visibility);
	}

	let closeBasket = () => {
		setVisibility(false);
		onblur(false);
	}

	let openShopNav = () => {
		setShopNav(!shopnav);
		setSearch(false);
		setVisibility(false);
		setMenuNav(false);
		onblur(false);
	}

	let openMenuNav = () => {
		setSearch(false);
		setShopNav(false);
		setVisibility(false);
		onblur(false);
	}

	React.useEffect(() => {
      setVisibility(basketVisibility);
		if(basketVisibility) {
			onblur(basketVisibility);
		}
   }, [basketVisibility]);


	return (
      <header ref={ref}>
      		<Discount></Discount>
			 	<div className="header__wrapper">
				<Menu menunavProp={menunav} handleMenuChange={openMenuNav}></Menu>
         	<a href="/" className="logo"><span className="logo-mark"><LogoMark /></span>TopspinTT</a>
				<span className={"shop-nav-item "  + (shopnav ? 'shop-nav-item--active' : '')  + (search ? ' shop-nav-item--hidden' : '')} onClick={() => openShopNav()}>Shop</span>
					<div className={"search-box "  + (search ? 'search-box--visible' : '')}>
						<label htmlFor="search">Search</label>
						<input type="search" name="search" id="search" autoComplete="off" />
					</div>
					<div className="header__icons">
						<div className="search-icon" onClick={() => openSearch()}><SearchIcon /></div>
						<div className="basket-icon" onClick={() => openBasket()}>
							<BasketIcon />
							{ items.length > 0 && <div className="basket-icon__counter">{ basketItems }</div> }
						</div>
					</div>
			</div>
			<Basket visibilityProp={visibility} closeBasket={closeBasket}></Basket>
			<Search searchProp={search}></Search>
			<ShopMenu shopnavProp={shopnav} toggleOverlay={openShopNav}></ShopMenu>
			<div className={"nav-overlay "  + (shopnav ? 'nav-overlay--visible' : '')} onClick={() => setShopNav(false)}></div>
      </header>
   );
}
export default Header;
