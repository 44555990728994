import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";

const Menu = ( { menunavProp, handleMenuChange } ) => {

	const [menuVisibility, setMenuVisibility] = React.useState(false);

	const menuChange = handleMenuChange;

	useEffect(() => {
		setMenuVisibility(menunavProp);
	},[menunavProp]);

	const handleMenuOpen = () => {
		setMenuVisibility(!menuVisibility);
		menuChange();
	}


	return (
      <div className="menu">
			<div className={"menu__burger "  + (menuVisibility ? 'menu__burger--visible' : '')} onClick={() => handleMenuOpen()}>
				<div className="menu__burger__line"></div>
				<div className="menu__burger__line"></div>
				<div className="menu__burger__line"></div>
			</div>
			<div className={"menu__backing "  + (menuVisibility ? 'menu__backing--visible' : '')}></div>
			<nav id="main-nav" className={(menuVisibility ? 'nav--visible' : '')}>
				<p><strong><NavLink to="/about/" onClick={() => setMenuVisibility(false)}>About TopspinTT</NavLink></strong><br />TOPSPIN Sports Ltd is a London based specialist table tennis equipment supplier, hire and events company.<br /><NavLink to="/about/" className="menu__more-link" onClick={() => setMenuVisibility(false)}>find out more</NavLink></p>
				<ul aria-label="Products">
					<li><NavLink to="/products/blades" onClick={() => setMenuVisibility(false)}>Blades</NavLink></li>
					<li><NavLink to="/products/rubbers" onClick={() => setMenuVisibility(false)}>Rubbers</NavLink></li>
					<li><NavLink to="/products/bats" onClick={() => setMenuVisibility(false)}>Bats</NavLink></li>
					<li><NavLink to="/products/tables" onClick={() => setMenuVisibility(false)}>Tables</NavLink></li>
					<li><NavLink to="/products/robots" onClick={() => setMenuVisibility(false)}>Robots</NavLink></li>
					<li><NavLink to="/products/clothing" onClick={() => setMenuVisibility(false)}>Clothing</NavLink></li>
					<li><NavLink to="/products/shoes" onClick={() => setMenuVisibility(false)}>Shoes</NavLink></li>
					<li><NavLink to="/products/club-equipment" onClick={() => setMenuVisibility(false)}>Club Equipment</NavLink></li>
					<li><NavLink to="/products/balls" onClick={() => setMenuVisibility(false)}>Balls</NavLink></li>
					<li><NavLink to="/products/at-home" onClick={() => setMenuVisibility(false)}>At Home</NavLink></li>
					<li><NavLink to="/products/sale" onClick={() => setMenuVisibility(false)}>Sale</NavLink></li>
				</ul>
				<ul aria-label="Our Company">
					<li><NavLink to="/privacy-policy/" onClick={() => setMenuVisibility(false)}>Privacy Policy</NavLink></li>
					<li><NavLink to="/contact/" onClick={() => setMenuVisibility(false)}>Contact TopspinTT</NavLink></li>
					<li><NavLink to="/delivery-charges/" onClick={() => setMenuVisibility(false)}>Delivery Charges</NavLink></li>
				</ul>
				<p><strong><a href="/tablehire/">Table Hire & Events</a></strong><br />Premium table tennis table and equipment hire for tournaments, office events, sports days, parties and more.<br /><a href="/" className="menu__more-link" onClick={() => setMenuVisibility(false)}>find out more</a></p>
				<button type="button" onClick={() => setMenuVisibility(false)} className="nav--close">close</button>
			</nav>
		</div>
   );
}
export default Menu;
