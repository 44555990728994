import React, { useState } from 'react';
import { NavLink, Link } from "react-router-dom";
import LazyImage from '../lazyload.js';

const ShopMenu = ({shopnavProp,toggleOverlay}) => {
	const [visibility, setVisibility] = React.useState({...shopnavProp});

	React.useEffect(() => {
      setVisibility(shopnavProp);
  }, [shopnavProp])

  	const handleClick = () => {
		setVisibility(false);
		toggleOverlay();
	}

	return (
		<nav id="shop-nav" className={"shop-nav "  + (visibility ? 'shop-nav--visible' : '')}>
			<div className="shop-nav__wrapper">
				<div className="shop-nav__products">
					<h3>Products <Link to="/products" onClick={() => handleClick()}>Shop all</Link></h3>
					<ul>
						<li>
							<span className="shop-nav__img"><LazyImage width="" height="" src="/assets/images/nav_blades.jpg" webp="/assets/webp/nav_blades.webp" delay="0" alt="shop Blades" /></span>
							<span className="shop-nav__text">
							<NavLink to="/products/blades" className="shop-nav__cat-link" onClick={() => handleClick()}>Blades</NavLink>
							<NavLink to="/products/blades" className="shop-nav__small-link" onClick={() => handleClick()}>Shop now</NavLink>
							</span>
						</li>
						<li>
							<span className="shop-nav__img"><LazyImage width="" height="" src="/assets/images/nav_rubbers.jpg" webp="/assets/webp/nav_rubbers.webp" delay="0" alt="shop Rubbers" /></span>
							<span className="shop-nav__text">
							<NavLink to="/products/rubbers" className="shop-nav__cat-link" onClick={() => handleClick()}>Rubbers</NavLink>
							<NavLink to="/products/rubbers" className="shop-nav__small-link" onClick={() => handleClick()}>Shop now</NavLink>
							</span>
						</li>
						<li>
							<span className="shop-nav__img"><LazyImage width="" height="" src="/assets/images/nav_bats.jpg" webp="/assets/webp/nav_bats.webp" delay="0" alt="shop Bats" /></span>
							<span className="shop-nav__text">
							<NavLink to="/products/bats" className="shop-nav__cat-link" onClick={() => handleClick()}>Bats</NavLink>
							<NavLink to="/products/bats" className="shop-nav__small-link" onClick={() => handleClick()}>Shop now</NavLink>
							</span>
						</li>
						<li>
							<span className="shop-nav__img"><LazyImage width="" height="" src="/assets/images/nav_tables.jpg" webp="/assets/webp/nav_tables.webp" delay="0" alt="shop Tables" /></span>
							<span className="shop-nav__text">
							<NavLink to="/products/tables" className="shop-nav__cat-link" onClick={() => handleClick()}>Tables</NavLink>
							<NavLink to="/products/tables" className="shop-nav__small-link" onClick={() => handleClick()}>Shop now</NavLink>
							</span>
						</li>
						<li>
							<span className="shop-nav__img"><LazyImage width="" height="" src="/assets/images/nav_robots.jpg" webp="/assets/webp/nav_robots.webp" delay="0" alt="shop Robots" /></span>
							<span className="shop-nav__text">
							<NavLink to="/products/robots" className="shop-nav__cat-link" onClick={() => handleClick()}>Robots</NavLink>
							<NavLink to="/products/robots" className="shop-nav__small-link" onClick={() => handleClick()}>Shop now</NavLink>
							</span>
						</li>
						<li>
							<span className="shop-nav__img"><LazyImage width="" height="" src="/assets/images/nav_clothing.jpg" webp="/assets/webp/nav_clothing.webp" delay="0" alt="shop Clothing" /></span>
							<span className="shop-nav__text">
							<NavLink to="/products/clothing" className="shop-nav__cat-link" onClick={() => handleClick()}>Clothing</NavLink>
							<NavLink to="/products/clothing" className="shop-nav__small-link" onClick={() => handleClick()}>Shop now</NavLink>
							</span>
						</li>
						<li>
							<span className="shop-nav__img"><LazyImage width="" height="" src="/assets/images/nav_sale.jpg" webp="/assets/webp/nav_sale.webp" delay="0" alt="shop Sale" /></span>
							<span className="shop-nav__text">
							<NavLink to="/products/sales" className="shop-nav__cat-link" onClick={() => handleClick()}>Sale</NavLink>
							<NavLink to="/products/sale" className="shop-nav__small-link" onClick={() => handleClick()}>Shop now</NavLink>
							</span>
						</li>

					</ul>
				</div>
				<div className="shop-nav__advice">
				</div>
			</div>
		</nav>

   );
}
export default ShopMenu;
