const Contact = () => {
   return (
     <section className="content">
     <h1>Contact TopSpinTT</h1>
     <p><strong>Company registered address</strong><br />
117 Colchester Road<br />
Leyton<br />
London E10 6HD
</p>
<p><strong>Company Reg Number</strong><br />
7115375</p>
<p><strong>VAT Number</strong><br />
984 9949 28</p>
     </section>
   )
}
export default Contact;
