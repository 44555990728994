import React, { useState, useEffect } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import { saveState } from '../../localStorage';
import { Link } from "react-router-dom";
import Checkout from './checkout.js';
import { formatNumber } from '../../utils.js';

const selectItems = state => state.items;

const Basket = ({visibilityProp,closeBasket}) => {
	const [visibility, setVisibility] = React.useState({...visibilityProp});
	const [scrollTop, setScrollTop] = React.useState({...visibilityProp});
	const [scrolling, setScrolling] = React.useState({...visibilityProp});
	const dispatch = useDispatch();
	const ref = React.useRef(null);
	let basketTotal = 0;

	const items = useSelector(selectItems);

	saveState(items);

	items.map(item => { basketTotal = basketTotal + (item.price*item.quantity); })

	React.useEffect(() => {
      setVisibility(visibilityProp);
  }, [visibilityProp])

  useEffect(() => {
    const onScroll = e => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  const handleRemoval = (itemId) => {
    dispatch({ type: 'cart/cartDeleted', payload: { id:itemId } })
  }

	const freeshippingvar = basketTotal < 60 ? (basketTotal/60)*100 : 100;
  const freeshipping = { "--free-shipping": (freeshippingvar)+"%" };

  const handleClose = () => {
	  closeBasket();
	  setVisibility(false);
  }
	return (
      <div className={"basket "  + (visibility ? 'basket--visible' : '') + (scrollTop > 116 ? ' basket--floating' : '')}>
			<div className="basket__wrapper">
			<h4>Your cart { items.length > 0 && <span>:  £{ formatNumber(basketTotal) }</span> }</h4>
			<div className="basket__barometer">
				<div className="basket__barometer__line" style={freeshipping}></div>
				{ basketTotal > 60 && <p>You have qualified for free shipping</p> }
				{ basketTotal < 60 && <p>You are currently £{ formatNumber(60 -  basketTotal) } away from free shipping</p> }
			</div>
			<div className="basket__items">
			{
	          items.map((item,index) =>
				 <div className="basket__item" key={index}>
 					<div className="basket__item__img"><Link to={ item.url } onClick={() => handleClose()} title={ item.title }><img src={ item.img } loading="lazy" alt={ item.title } /></Link></div>
 					<div className="basket__item__txt">
 						<p><span className="basket__item__title"><Link to={ item.url } onClick={() => handleClose()} title={ item.title }>{ item.title }</Link></span><br />
						{ item.desc !== "Default Title" && <span className="basket__item__options">{ item.desc }<br /></span> }
						<span className="basket__item__price">{ item.quantity > 1 && <>{ item.quantity } <span className="basket__item__multiplier">x</span></> } £{ formatNumber(item.price) }</span>
 						<button className="basket__item__remove" onClick={() => handleRemoval(item.id)}>Remove</button>
 						</p>
 					</div>
 				</div>
	          )
	        }
			  {items.length == 0 && <p><small>Your basket is empty. Start shopping!</small></p>}
			</div>
			{items.length > 0 && <Checkout /> }
			<button type="none" className="basket__close" onClick={() => handleClose()}>close</button>
			</div>
		</div>
   );
}
export default Basket;
