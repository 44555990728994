const DeliveryCharges = () => {
   return (
     <section className="content">
               		<h1>Delivery, Charges & Returns</h1>
                    <p>To place an order on our website simply click on <strong>add to basket</strong> next to the product you would like to buy. The product will be added to the shopping basket at the top right hand side of the screen. The shopping basket will display the number of items you have ordered and a rolling total of the total amount. All prices are shown in Pounds Sterling and are inclusive of VAT.</p>
                    <p>Once you have completed selecting your item(s) click on the <strong>shopping basket</strong> and a list of the item(s) you have ordered will be displayed. From the shopping basket you can remove and add item(s). Once you are happy with the order click on <strong>go to checkout</strong>, confirm selection and click continue, enter your delivery details and set up an optional account with Topspin and click <strong>continue</strong>. You will now be in confirm and pay where you can make final changes to your order or delivery details. Once you are happy click on Checkout with Paypal or Google Checkout to pay for the order.</p>
                    <p><strong>We take the security of your payment details extremely seriously and the payment processing on our website is through Paypal (or Google Checkout) which means that at no time are your bank account details requested or stored on our website.</strong></p>
                    <h3>Alternative Ways to Order</h3>
                    <p>If you prefer we also take orders by email and telephone.</p>
                        <p><strong>Email:</strong> To order via email please send to <a href="mailto:orders@topspintt.com">orders@topspintt.com</a> with the items you would like to order and also include a telephone no. that you can be contacted on.</p>
                        <p><strong>Telephone:</strong> Please phone your order through to 0208 279 4099, if we are not available to take your call please leave a message with your contact telephone details and a TOPSPIN representative will call you back ASAP.</p>
                        <p>Please note that payment must have been received in full before the goods will be dispatched.</p>
                        <h3>Payment options for Email and Telephone orders</h3>
                        <p><strong>Cheque</strong><br /> Cheques should be denominated in Pounds Sterling and drawn on a UK bank. Please make your cheque payable to: Topspin Sports Ltd. Please remember to write your home address and cheque card details on the back of your cheque.</p>
                        <p><strong>Personal online banking</strong><br /> Please contact TOPSPIN and we will provide our bank details so that you can send us payment via your online banking provider.</p>
                        <p><strong>Sterling draft</strong><br /> Please make your sterling draft payable to: Topspin Sports Ltd</p>
                        <p><strong>Postal order</strong><br /> Please make your postal orders payable to: Topspin Sports Ltd</p>
                        <p><strong>Cash</strong><br /> Cash payment can be made in UK sterling only. For your own security, please ensure that you send any cash by REGISTERED MAIL.</p>
                        <h3>Order processing and delivery times</h3>
                        <p>Topspin Sports aims to arrange dispatch of all items held in stock within 48 hours of receiving your order. Where products are not held in stock we will always contact you by email or telephone with an estimated dispatch date which should be no longer than 14 days from date of order.</p>
                        <p>UK mainland orders up to the weight of 1.5kg are sent by Royal Mail 1st Class mail. Please allow at least 48 hours for actual delivery as 1st Class post does not guarantee a next day delivery. UK mainland items over 1.5kg are dispatched via Royal Mail standard service. Please allow at least 5 days for delivery.</p>
                        <h3>Postage and Packaging Charges</h3>
                        <p><strong>Orders under £60:</strong> P&amp;P Charge £3.99<br /><strong>Orders over £60:</strong> P&amp;P Free</p>
                        <p>For certain very large items additional carriage may be due, this will always be confirmed and agreed with customer prior to dispatch.</p>
                        <h3>Returns Policy</h3>
                        <p>If you would like to exchange or return a product that you are not happy with, the original product must be returned to us in its original, unopened condition within 14 days from the date of dispatch. You will be responsible for the item(s) until they reach us so for your own protection we suggest you use a secure delivery method that requires a signature upon delivery. Please send the returns to Topspin Sports Ltd, 117 Colchester Road, Leyton, London E10 6HD. On receipt of the returned product, goods will either be exchanged or a full refund will be made less the original postage and packaging costs.</p>
                        <p>Please note that we cannot refund or exchange any 'customised' bats. Any complete bats that have been made up to your specific requirements cannot be returned if you have specified the wrong items or decide you do not like the bat.</p>
                        <h3>Faulty Products</h3>
                        <p>If a product is faulty, we will replace the item or refund the product cost in full but you must notify us of any defects within 10 days of dispatch of the goods. This is in addition to your statutory rights.</p>
                        <h3>Complaints</h3>
                        <p>We take our customer service very seriously and welcome all feedback regarding our service and we aim to deal with any problems or difficulties experienced by our customers both quickly and fairly.</p>
     </section>
   )
}
export default DeliveryCharges;
