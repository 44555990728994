import React, { createRef, useState } from 'react';
import {ReactComponent as LogoMark } from '../../svgs/logomark.svg';
import {ReactComponent as Twitter } from '../../svgs/twitter.svg';
import {ReactComponent as Facebook } from '../../svgs/facebook.svg';
import { Link } from "react-router-dom";

const Footer = () => {

   const [email, setEmail] = useState('');

   const labelRef = React.createRef();

   const focusTextInput = () => {
      labelRef.current.classList.add('is-focused');
   }

   const blurTextInput = () => {
      if(!email) {
         labelRef.current.classList.remove('is-focused');
      }
   }

   const handleSubmit = (event) => {
      event.preventDefault();
   }

  return (

    <footer>
      <div className="footer__logomark">
         <LogoMark />
      </div>
      <div className="footer__blocks">
         <div className="footer__cols">
            <div className="footer__col">
               <h4>Shop</h4>
               <ul>
                  <li><Link to="/about/">Our story</Link></li>
                  <li><Link to="/delivery-charges/">Delivery charges</Link></li>
                  <li><Link to="/privacy-policy/">Privacy policy</Link></li>
               </ul>
            </div>
            <div className="footer__col">
               <h4>Follow</h4>
               <div className="footer__social">
                  <a href="https://www.facebook.com/topspinttsports/" target="_blank" rel="nofollow noopener" className="facebook" title="find TopspinTT on Facebook"><Facebook /></a>
                  <a href="https://www.twitter.com/topspintt" target="_blank" rel="nofollow noopener" className="twitter" title="find TopspinTT on Twitter"><Twitter /></a>
               </div>
            </div>
            <div className="footer__col">
               <h4>Contact</h4>
               <p>117 Colchester Road<br />
               London, E10 6HD</p>
               <p><a href="mailto:hello@topspintt.com">hello@topspintt.com</a></p>
            </div>
         </div>
         <hr />
         <div className="footer__newsletter">
            <h4>Stay up to date with the latest product news & offers. <br />
            Subscribe to our newsletter.</h4>
            <form onSubmit={handleSubmit}>
               <label htmlFor="email" ref={labelRef}>Enter your email address</label>
               <input type="email" name="email" id="email" value={email} onFocus={() => focusTextInput()} onBlur={() => blurTextInput()} onChange={e => setEmail(e.target.value)} />
               <input type="submit" name="subscribe" id="subscribe" value="Subscribe" />
            </form>
            <p><small>Your email address will only be used for marketing emails from TopspinTT and will not be passed onto any third party.</small></p>
         </div>
      </div>
      <div className="footer__copyright">
      <p>Topspin Table Tennis. Table Tennis for Professionals. Copyright 2021</p>
      </div>
    </footer>
  )
}

export default Footer;
