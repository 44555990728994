import React, { useState, useEffect } from 'react';
import Header from './components/header/header.js';
import Footer from './components/footer/footer.js';
import DeliveryCharges from './components/content/delivery-charges.js';
import PrivacyPolicy from './components/content/privacy-policy.js';
import Contact from './components/content/contact.js';
import About from './components/content/about.js';
import Content from './components/content/content.js';
import FourohFour from './components/content/404/404.js';
import Home from './components/content/home/home.js';
import Search from './components/search/search.js';
import ProductList from './components/products/list.js';
import Products from './components/products/products.js';
import SingleProduct from './components/products/single-product.js';
import { BrowserRouter as Router, Route, NavLink, useLocation, Switch } from "react-router-dom";


const App = () => {

   const [blur, setBlur] = React.useState(false);
   const [basketVisibility, setBasketVisibility] = React.useState(false);

   const blurBackground = (state) => {
      setBlur(state);
   }

   const openBasket = () => {
      setBasketVisibility(true);
   }

	return (
      <>
         <Header onblur={blurBackground} basketprop={basketVisibility}></Header>
         <div className={"main " + (blur ? "is--blurred" : "") } role="main">
            <Switch>
               <Route path="/" exact>
                  <Home />
               </Route>
               <Route path="/products/*/*/">
                  <SingleProduct openbasket={openBasket}></SingleProduct>
               </Route>
               <Route path="/products/*/">
                  <ProductList></ProductList>
               </Route>
               <Route exact path="/products">
                  <Products></Products>
               </Route>
               <Route path="/delivery-charges/">
                  <DeliveryCharges></DeliveryCharges>
               </Route>
               <Route path="/privacy-policy/">
                  <PrivacyPolicy></PrivacyPolicy>
               </Route>
               <Route exact path="/about/">
                  <About></About>
               </Route>
               <Route exact path="/contact/">
                  <Contact></Contact>
               </Route>
               <Route path="/search">
                  <Search></Search>
               </Route>
               <Route path="*">
                  <FourohFour></FourohFour>
               </Route>
            </Switch>
         </div>
         <hr />
         <Footer></Footer>
		</>
   );
}
export default App;
