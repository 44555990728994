import LazyImage from '../lazyload.js';
import { Link } from "react-router-dom";
import ProductRow from '../products/product-row.js';

const Products = () => {
   return (
      <>
      <div className="leader">
         <h1>All our products</h1>
      </div>
      <hr />
      <section className="product-row--wrapper">
        <header>
           <h2>New to the site</h2>
        </header>
       <ProductRow collection="New" numberofproducts="4" />
     </section>
     <hr />
      <section className="our-range--wrapper">
        <header>
           <h2>Our Range</h2>
        </header>
        <div className="our-range">
           <div className="range">
              <div className="range__img">
                <LazyImage width="" height="" src="/assets/images/blades_980.jpg" webp="/assets/webp/blades_980.webp" delay="0" />
              </div>
              <h3>Rubbers</h3>
              <p>We've partnered with the best brands to bring you the technological cutting edge of table tennis.</p>
              <p><Link to="/products/rubbers">Shop now</Link></p>
           </div>
           <hr />
           <div className="range">
              <div className="range__img">
                <LazyImage width="" height="" src="/assets/images/rubbers_980.jpg" webp="/assets/webp/rubbers_980.webp" delay="0" />
              </div>
              <h3>Blades</h3>
              <p>Whether you prefer a looser style of play or love a tight, fast rally, weve got the blades to suit your batting technique.</p>
              <p><Link to="/products/blades">Shop now</Link></p>
           </div>
           <hr />
           <div className="range">
              <div className="range__img">
                <LazyImage width="" height=""  src="/assets/images/tables_980.jpg" webp="/assets/webp/tables_980.webp" delay="0" />
              </div>
              <h3>Tables</h3>
              <p>For practise or for competition, we've got a wide range of tables to suit your needs.</p>
              <p><Link to="/products/tables">Shop now</Link></p>
           </div>
           <div className="range">
             <div className="range__img">
                <LazyImage width="" height=""  src="/assets/images/robots_980.jpg" webp="/assets/webp/robots_980.webp" delay="0" />
             </div>
             <h3>Robots</h3>
             <p>Man vs machine? Practise your batting skills against the robots.</p>
             <p><Link to="/products/robots">Shop now</Link></p>
           </div>
            <hr />
           <div className="range">
             <div className="range__img">
                <LazyImage width="" height=""  src="/assets/images/clothing_980.jpg" webp="/assets/webp/clothing_980.webp" delay="0" />
             </div>
             <h3>Clothing</h3>
             <p>Ease of movement and comfort is essential for any sports clothing.</p>
             <p><Link to="/products/clothing">Shop now</Link></p>
           </div>
           <hr />
          <div className="range">
           <div className="range__img">
               <LazyImage width="" height=""  src="/assets/images/balls_980.jpg" webp="/assets/webp/balls_980.webp" delay="0" />
           </div>
           <h3>Balls</h3>
           <p>Ease of movement and comfort is essential for any sports clothing.</p>
           <p><Link to="/products/balls">Shop now</Link></p>
          </div>
        </div>
     </section>
      <hr />

     <section className="product-row--wrapper">
      <header>
          <h2>On Sale</h2>
      </header>
      <ProductRow collection="New" numberofproducts="4" />
    </section>
      </>
   )
}

export default Products;
