import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import { BrowserRouter as Router } from "react-router-dom";
import { ApolloProvider } from '@apollo/client/react';
import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import { Provider } from 'react-redux';
import store from './store/store';
import ScrollToTop from "./scrolltoTop";

const httpLink = createHttpLink({
  uri: 'https://topspin-sports-ltd.myshopify.com/api/2021-07/graphql.json',
});

const authLink = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      'X-Shopify-Storefront-Access-Token': "8078f967f533e48a01bf17b294683057",
    }
  }
});

const client = new ApolloClient({ link: authLink.concat(httpLink), cache: new InMemoryCache() });


ReactDOM.render(
   <React.StrictMode>
      <ApolloProvider client={client}>
         <Router>
            <ScrollToTop />
            <Provider store={store}> <App /> </Provider>
         </Router>
      </ApolloProvider>
   </React.StrictMode>,
   document.getElementById('topspintt')
);
