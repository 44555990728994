const About = () => {
   return (
     <section className="content">
         <h1>About TopspinTT</h1>
                    	<p>TOPSPIN Sports Ltd is a London based specialist table tennis equipment supplier, hire and events company.
							TOPSPIN Sports was founded in 2010 by John Dennison &amp; Tom Cutler.  Both John &amp; Tom are table tennis experts with 30+ years’ experience of high level table tennis.</p>
<h3>John Dennison</h3>
<p>John has played table tennis to an international level and is also one of the leading table tennis coaches in the UK.  He is head coach of Fusion TTC, coaches some of the best young players in England and regularly travels to international competitions with his players.  John heads up our competition, social and corporate table tennis events.</p>
<h3>Tom Cutler</h3>
<p>Tom has played table tennis to a national level, has won the British Premier League title with Fusion TTC and also won a bronze medal in the O40 World Veterans Doubles Championships.   Tom heads up the operations and day to day running of Topspin and is very knowledgeable on our product range.</p>
<p>The ethos behind TOPSPIN Sports is a passion for table tennis which we strive to share with our customers through:</p>
<ul>
	<li>The quality and range of our products and services</li>
<li>Responsive customer service</li>
<li>Sound technical advice</li>
<li>Competitive prices</li>
</ul>
<p>TOPSPIN Sports are equally passionate about building strong relationships:</p>
<p>TOPSPIN Sports are proud to be one of the main UK distributors of the world renowned table tennis brand DONIC. DONIC equipment has been used by 16 World Table Tennis Champions and 33 European Table Tennis Champions.  Donic sponsor the current European no. 1 table tennis player Dima Ovtcharov.</p>

<p>Topspin Sports are also distributors for the following brands:</p>
<ul>
	<li>Newgy - One of the leading companies in table tennis robots</li>
<li>Nittaku – World famous Japanese table tennis equipment and ball manufacturer.</li>
<li>Sanwei – specialist table tennis equipment and balls manufacturer from China.  Official ball supplier to Table Tennis England Competitions 2018-19</li>
</ul>

<p>Topspin Sports is also proud of its close associations with table tennis clubs such as: </p>

<ul>
	<li>Batts TTC – Premier league club based in Harlow, Essex</li>
	<li>Brighton TTC – TT England National Club of the Year 2015</li>
	<li>Fusion TTC – Premier League club and one of the largest clubs in London.</li>
	<li>Norwich City TTC – Large table tennis club based in Norwich</li>
	<li>Ormeau TTC – British Premier club and largest in Northern Ireland </li>
	<li>Portsmouth TTC – Large table tennis club based in Portsmouth </li>
	<li>Waterside TTC – Large table tennis club based in Southampton</li>
	<li>West London Ping – Umbrella organisation that promotes table tennis across West London</li>
</ul>

<p>TOPSPIN Sports is committed to the development of table tennis, helping promising young players and offering players the chance to try out new equipment through bat testing workshops.</p>
<p>If you are a club or school interested in organising a bat testing workshop or a club or school that is interested in forming an association with TOPSPIN Sports please email hello@topspintt.com.</p>
     </section>
   )
};
export default About;
