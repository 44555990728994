import {Helmet} from "react-helmet";
import { useLocation, useParams, useRouteMatch, matchPath, Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import { useQuery, gql } from '@apollo/client';
import LazyImage from '../lazyload.js';
import { formatNumber } from '../../utils.js';
import {ReactComponent as Filter } from '../../svgs/filters.svg';

const ProductList = () => {

   const location = useLocation();
   const [products, setProducts] = React.useState([]);
   const [trimmedList, setTrimmedList] = React.useState([]);
   const [brandList, setBrandList] = React.useState([]);
   const [totalProducts, setTotalProducts] = React.useState(0);

   const [order, setOrder] = React.useState('title');
   const [brand, setBrand] = React.useState('');
   const [limit, setLimit] = React.useState(20);

   const GET_PRODUCTBYHANDLE = gql`
query shop($handle: String!) {
  collectionByHandle(handle: $handle) {
     id
     products(first: 250) {
       edges {
          node {
            id
            title
            vendor
            handle
            createdAt
            images(first: 10) {
            edges {
              node {
              	id
              	altText
              	originalSrc
               transformedSrc(scale: 1, preferredContentType: WEBP)
              }
            }
          }
          collections(first: 5) {
            edges {
              node {
                handle
                title
              }
            }
          }
          metafields(first: 5) {
      edges {
        node {
          key
          value
        }
      }
    }
          priceRange {
             minVariantPrice {
               amount
             }
           }
            variants(first: 10) {
              edges {
                node {
                  id
                  sku
                  price
                  selectedOptions {
                    name
                    value
                  }
                }
              }
            }
          }
          cursor
       }
       pageInfo {
          hasNextPage
       }
     }
  }
}
`;

   const routes = [{
   key: 'blades',
   title: 'Blades',
   id: 1,
   exact: true,
   path: '/products/blades',
   desc: 'Getting the right table tennis blade to suit your style of play is essential to enable you to play your best table tennis. We offer top quality table tennis blades at competitive prices.'
   },{
   key: 'rubbers',
   title: 'Rubbers',
   id: 2,
   exact: true,
   path: '/products/rubbers',
   desc: 'The technological cutting edge of table tennis.'
   },{
   key: 'bats',
   title: 'Bats',
   id: 3,
   exact: true,
   path: '/products/bats',
   desc: 'High quality table tennis bats delivered throughout the UK.'
   },{
   key: 'tables',
   title: 'Tables',
   id: 4,
   exact: true,
   path: '/products/tables',
   desc: 'Top quality table tennis tables at competitive prices with our extensive range.'
   },{
   key: 'robots',
   title: 'Robots',
   id: 5,
   exact: true,
   path: '/products/robots',
   desc: 'The technological cutting edge of table tennis.'
   },{
   key: 'clothing',
   title: 'Clothing',
   id: 6,
   exact: true,
   path: '/products/clothing',
   desc: 'Top quality and fashionable table tennis specific clothing.'
   },{
   key: 'shoes',
   title: 'Shoes',
   id: 7,
   exact: true,
   path: '/products/shoes',
   desc: ''
   },{
   key: 'club-equipment',
   title: 'Club Equipment',
   id: 8,
   exact: true,
   path: '/products/club-equipment',
   desc: ''
   },{
   key: 'balls',
   title: 'Balls',
   id: 9,
   exact: true,
   path: '/products/balls',
   desc: 'Top quality table tennis balls at competitive prices'
   },{
   key: 'sale',
   title: 'Sale',
   id: 10,
   exact: true,
   path: '/products/sale',
   desc: ''
   },{
   key: 'at-home',
   title: 'At Home',
   id: 11,
   exact: true,
   path: '/products/at-home',
   desc: 'Carefully selected range of equipment to meet your at home table tennis needs.'
   },
   ]

   const currentRoute = routes.find(
     route => matchPath(location.pathname, route)
   )

   const { loading, error, data } = useQuery(
      GET_PRODUCTBYHANDLE,
      { variables: { handle: currentRoute.key } }
   );

   const handleOrderChange = (order) => {
      setOrder(order);
      setTrimmedList(reorderProds(order));
   }

   const handleBrandFilter = (brand) => {
      setBrand(brand);
      if(brand.length > 0) {
         var newList = products.filter((product, i) => { return product.brand == brand; } );
         let citrus = newList.slice(0, limit);
         setTrimmedList(citrus);
      } else {
         let citrus = products.slice(0, limit);
         setTrimmedList(citrus);
      }
   }

   useEffect(() => {
      let productArray = [];
      let brandMap = new Set();
      if(data) {
         if(data.collectionByHandle) {
            setTotalProducts(data.collectionByHandle.products.edges.length);

            data.collectionByHandle.products.edges.map((product, index) => {

               productArray.push({
                  'title':product.node.title,
                  'brand':product.node.vendor,
                  'imgurl':product.node.images.edges[0].node.originalSrc,
                  'webpurl':product.node.images.edges[0].node.transformedSrc,
                  'imgalt':product.node.images.edges[0].node.altText,
                  'price':product.node.priceRange.minVariantPrice.amount,
                  'date':product.node.createdAt,
                  'url':"/products/" + product.node.collections.edges[0].node.handle + "/" + product.node.handle
               });
               brandMap.add(product.node.vendor);
            });
         }
      }

      setBrandList(brandMap);
      setProducts(productArray);
      let citrus = productArray.slice(0, limit);
      setTrimmedList(citrus);
   }
   ,[data]);

   useEffect(() => {
      let citrus = products.slice(0, limit);
      setTrimmedList(citrus);
   },[limit]);

   useEffect(() => {
      if(trimmedList.length === 0 && data) {
         let productArray = [];
         let brandMap = new Set();
         if(data) {
            if(data.collectionByHandle) {
               setTotalProducts(data.collectionByHandle.products.edges.length);

               data.collectionByHandle.products.edges.map((product, index) => {

                  productArray.push({
                     'title':product.node.title,
                     'brand':product.node.vendor,
                     'imgurl':product.node.images.edges[0].node.originalSrc,
                     'webpurl':product.node.images.edges[0].node.transformedSrc,
                     'imgalt':product.node.images.edges[0].node.altText,
                     'price':product.node.priceRange.minVariantPrice.amount,
                     'date':product.node.createdAt,
                     'url':"/products/" + product.node.collections.edges[0].node.handle + "/" + product.node.handle
                  });
                  brandMap.add(product.node.vendor);
               });
            }
         }

         setBrandList(brandMap);
         setProducts(productArray);
         let citrus = productArray.slice(0, limit);
         setTrimmedList(citrus);
      }
   },[]);

   const reorderProds = (ordered) => {
      let sortableItems = [...products];
      sortableItems.sort(function(a, b) {
         if (a[ordered] < b[ordered]) return -1;
         if (a[ordered] > b[ordered]) return 1;
         return 0;
      } );
      let citrus = sortableItems.slice(0, limit);
      return citrus;
   }

   if(!currentRoute) {
      return (
         <div>
            <div className="leader">
               <h1>Category not found</h1>
               <p>Sorry, the {location.pathname} category can't be found.</p>
            </div>
         </div>
      )
   }

   const orphaned = { "--orphaned-products": (totalProducts % 4) };

   if (loading) return (
      <>
      <div className="leader">
         <h1>Table Tennis {currentRoute.key}</h1>
         <p>{currentRoute.desc}</p>
      </div>
      <div className="products is-loading"></div>
      </>
   );
   if (error) return <p>Error :(</p>


   return (
      <>
      <div className="leader">
         <h1>Table Tennis {currentRoute.title}</h1>
         <p>{currentRoute.desc}</p>
      </div>
      <div className="category-img">
      <LazyImage width="" height="" src={ "/assets/images/"+ ( currentRoute.key ) + "_680.jpg" } webp={ "/assets/webp/"+ ( currentRoute.key ) + "_680.webp" } delay="0" alt={currentRoute.title} />
      </div>
      <div className={"products is-full-list " + "is-modulus--"+(totalProducts % 4) + ( limit > trimmedList.length ? " is-full-list" : "")} >
         <div className="filters">
            <div className="filters__select">
               <div className="filters__select__icon"><Filter /></div>
               <select name="order" value={brand} onChange={e => handleBrandFilter(e.currentTarget.value)}>
                  <option value="">Brand</option>
               { Array.from(brandList).map((brand, index) => (
                  <option value={brand} key={index}>{ brand }</option>
               ))}
               </select>
            </div>
            <div className="filters__meta">
               <p className="filters__count">{ totalProducts } product{totalProducts == 1 ? "" : "s"}</p>
               <div className="filters__select">
                  <div className="filters-select__icon"></div>
                  <select name="order" value={order} className="is-order" onChange={e => handleOrderChange(e.currentTarget.value)}>
                     <option value="date">Date Added</option>
                     <option value="price">Price (High to Low)</option>
                     <option value="title">A - Z</option>
                  </select>
               </div>
            </div>
         </div>
         <div className="product-row">
         { trimmedList.map((product, index) => (
            <div className="product" key={index}>
               <div className="product__img">
                <Link to={product.url}><LazyImage width="" height="" src={product.imgurl} webp={product.webpurl} delay="0" alt={ product.imgalt } /></Link>
               </div>
               <div className="product__txt">
                <p className="product__txt__brand">{ product.brand }</p>
                <h3><Link to={product.url}>{ product.title }</Link></h3>
                <p className="product__txt__price">£{ formatNumber(product.price) }</p>
               </div>
            </div>
         )) }
         { totalProducts == 0 && <p className="is-empty">There aren't currently any products available in this category</p> }
         </div>
         { (trimmedList.length > 0 && limit < totalProducts) && <button type="button" className="products__morebtn" onClick={() => setLimit(limit+8)}>View more</button> }
      </div>
      <Helmet>
          <title>Table Tennis {currentRoute.title} from TopspinTT</title>
          <meta name="description" content={ "Top quality Table Tennis "+ currentRoute.title +" as used by the pros. Free shipping on orders over £60." } />
      </Helmet>
</>
   )
}
export default ProductList;
