import { useQuery, gql } from '@apollo/client';
import LazyImage from '../lazyload.js';
import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";

const ProductRow = ({ collection, numberofproducts }) => {

   const [products, setProducts] = React.useState([]);

   const GET_COLLECTIONBYHANDLE = gql`
query shop($handle: String!) {
  collectionByHandle(handle: $handle) {
     id
     products(first: 250) {
       edges {
          node {
            id
            title
            vendor
            handle
            createdAt
            images(first: 10) {
            edges {
              node {
              	id
              	altText
              	originalSrc
               transformedSrc(scale: 1, preferredContentType: WEBP)
              }
            }
          }
          collections(first: 5) {
            edges {
              node {
                handle
                title
              }
            }
          }
          priceRange {
             minVariantPrice {
               amount
             }
           }
            variants(first: 10) {
              edges {
                node {
                  id
                  sku
                  price
                  selectedOptions {
                    name
                    value
                  }
                }
              }
            }
          }
          cursor
       }
       pageInfo {
          hasNextPage
       }
     }
  }
}
`;

const { loading, error, data } = useQuery(
   GET_COLLECTIONBYHANDLE,
   { variables: { handle: collection } }
);

useEffect(() => {
   if(data) {

      let productArray = [];

      data.collectionByHandle.products.edges.map((product, index) => {
         productArray.push({
            'title':product.node.title,
            'brand':product.node.vendor,
            'imgurl':product.node.images.edges[0].node.originalSrc,
            'webpurl':product.node.images.edges[0].node.transformedSrc,
            'imgalt':product.node.images.edges[0].node.altText,
            'price':product.node.priceRange.minVariantPrice.amount,
            'date':product.node.createdAt,
            'url':"/products/" + product.node.collections.edges[0].node.handle + "/" + product.node.handle
         });
      });

      setProducts(productArray);
   }
},[data]);

if (loading) return <p>Loading...</p>;
if (error) return <p>Error :(</p>


   return (
      <div className="product-row">
      { products.map((product, index) => (
         <div className="product" key={index}>
            <div className="product__img">
             <LazyImage width="" height="" src={product.imgurl} webp={product.webpurl} delay="0" alt={ product.imgalt } />
            </div>
            <div className="product__txt">
             <p className="product__txt__brand">{ product.brand }</p>
             <h3>{ product.title }</h3>
             <p className="product__txt__price">£{ product.price }</p>
             <Link to={ product.url } className="product__btn" title={ product.title }>shop now</Link>
            </div>
         </div>
         )) }
      </div>
   )
}

export default ProductRow;
